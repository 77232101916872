<template>
  <div>
    <loader v-if="loadingState" />
    <div class="pa-4" v-else>
      <v-row class="">
        <v-col
          class="mx-auto form_container"
          cols="12"
          xl="6"
          lg="6"
          md="9"
          sm="12"
        >
          <errors :errors="axiosError" />
          <br />
          <v-form>
            <!-- Name -->
            <v-text-field
              label="Name"
              placeholder="insert brand name"
              outlined
              full-width
              v-model="formData.name"
            ></v-text-field>

            <div class="text-center">
              <v-btn color="primary" @click="saveBtnHandler">Save</v-btn>
              <v-btn color="error">Cancel</v-btn>
            </div>
          </v-form>
        </v-col>
      </v-row>
      <v-row class="text-center mt-12">
        <data-table
          class="w-100"
          :tableData="brandDataArray"
          :tableHeaders="brandHeaderArray"
          @editHandler="editBrandData"
          @deleteHandler="deleteBrandData"
        />
      </v-row>
    </div>
  </div>
</template>

<script>
import * as api from "../config/api.js";
export default {
  beforeMount() {
    this.getAllBrand();
  },
  components: {},
  computed: {},
  data() {
    return {
      loadingState: false,
      brandDataArray: [],
      brandHeaderArray: [
        { text: "Id", value: "id" },
        { text: "Name", value: "name" },
        { text: "Date", value: "created_at" },
        { text: "", value: "actions" },
      ],
      formData: {
        name: "",
      },
      initFormData: {
        name: "",
      },
      axiosError: [],
      editMode: false,
    };
  },
  methods: {
    saveBtnHandler() {
      this.createBrandData();
    },
    getAllBrand() {
      this.loadingState = true;
      this.$http
        .get(api.BRAND)
        .then((res) => {
          this.brandDataArray = res.data;
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    createBrandData() {
      this.loadingState = true;
      this.$http
        .post(api.BRAND, { ...this.formData })
        .then((res) => {
          alert("Saved");
          console.log("Res==>", res.data);
          if (this.editMode) {
            this.brandDataArray = this.brandDataArray.map((e) => {
              if (e.id == res.data.id) {
                e = { ...res.data };
              }
              return e;
            });
          } else {
            this.brandDataArray.push(res.data);
          }
          this.reset();
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            this.axiosError = error.response.data;
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    editBrandData(e) {
      this.formData = { ...e };
      window.scrollTo(0, 0);
      this.editMode = true;
    },
    deleteBrandData(data) {
      if (confirm("Do you want to delete this?")) {
        this.loadingState = true;
        this.$http
          .delete(api.BRAND + "/" + data.id)
          .then((res) => {
            alert("Deleted");
            this.brandDataArray = this.brandDataArray.filter(
              (e) => e.id != data.id
            );
            this.reset();
          })
          .catch((error) => {
            alert("Error Occurred");
            console.log(" Error=======>", error.response.data);
            if (error.response.status == 403) {
              this.axiosError = error.response.data;
            }
          })
          .finally(() => {
            this.loadingState = false;
          });
      }
    },
    reset() {
      this.formData = { ...this.initFormData };
      this.loadingState = false;
      this.editMode = false;
      this.axiosError = [];
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped></style>
