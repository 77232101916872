<template>
  <div>
    <loader v-if="loadingState" />
    <div class="pa-4" v-else>
      <v-row class="">
        <v-col cols="12">
          <v-card-title>About Us</v-card-title>
        </v-col>
        <v-col
          class="mx-auto form_container"
          cols="12"
          xl="6"
          lg="6"
          md="9"
          sm="12"
        >
          <errors :errors="axiosError" />
          <br />
          <v-form>
            <!-- Name -->
            <div class="d-flex">
              <div class="w-100">
                <v-file-input
                  prepend-icon=""
                  outlined
                  label="File input"
                  @change="fileChangeHandler"
                  accept="image/*"
                  append-icon="fa-file-upload"
                  v-if="!formData.picPath"
                ></v-file-input>
                <div style="position:relative" v-else>
                  <v-img :src="formData.picPath"></v-img>
                  <v-btn
                    rounded
                    color="error"
                    style="position:absolute; top:0; right:0"
                    @click="removeImgHandler"
                  >
                    X
                  </v-btn>
                </div>
              </div>
              <span class="text-red">*</span>
            </div>
            <div class="d-flex">
              <tip-tap v-model="formData.message" class="w-100" />
              <span class="text-red">*</span>
            </div>
            <div class="text-center">
              <v-btn color="primary" @click="saveBtnHandler">Save</v-btn>
              <v-btn color="error" @click="reset">Cancel</v-btn>
            </div>
          </v-form>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import TipTap from "../components/tiptap/tiptap.vue";
import * as api from "../config/api.js";
export default {
  beforeMount() {
    this.getData();
  },
  components: {
    TipTap,
  },
  computed: {},
  data() {
    return {
      loadingState: false,
      formData: {
        picPath: "",
        message: "",
        section: "about",
      },
      initFormData: {
        picPath: "",
        message: "",
        section: "about",
      },
      axiosError: [],
    };
  },
  methods: {
    fileChangeHandler(event) {
      let reader = new FileReader();
      reader.readAsDataURL(event);
      reader.addEventListener("load", () => {
        this.formData.picPath = reader.result;
      });
    },
    saveBtnHandler() {
      console.log("formData==>", this.formData.description);
      this.loadingState = true;
      this.$http
        .post(api.ABOUT_US, { ...this.formData, section: "about" })
        .then((res) => {
          alert("Saved");
          if (res.data) {
            this.formData = res.data;
            console.log("Res==>", res.data);
          }
          // this.reset();
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            this.axiosError = error.response.data;
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    getData() {
      this.$http
        .get(api.ABOUT_US)
        .then((res) => {
          if (res.data) {
            this.formData = res.data;
            console.log("Res==>", res.data);
          }
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            this.axiosError = error.response.data;
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    reset() {
      this.formData.description = "Data ";
    },
    removeImgHandler() {
      this.formData.picPath = "";
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped></style>
