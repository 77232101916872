<template>
  <div>
    <loader v-if="loadingState" />
    <v-container v-else>
      <!-- Why Choose Us -->
      <v-row>
        <v-col cols="12">
          <div class="text-center section_headline">
            Why Choose Us ({{ whyChooseUsReasons.length }})
          </div>
        </v-col>
        <v-col cols="6">
          <div class="border">
            <div class="d-flex">
              <v-text-field
                outlined
                label="Headline"
                placeholder="Enter ..."
                counter="500"
                v-model="whyChooseUsFormData.headline"
              ></v-text-field>
              <span class="text-red">*</span>
            </div>
            <div class="d-flex">
              <v-textarea
                outlined
                label="Why Choose Us"
                placeholder="Enter ..."
                counter="1000"
                v-model="whyChooseUsFormData.message"
              ></v-textarea>
              <span class="text-red">*</span>
            </div>
            <div>
              <v-btn color="primary" @click="whyChooseUsSaveBtnHandler()"
                >Save</v-btn
              >
            </div>
          </div>
        </v-col>
        <v-col
          cols="6"
          v-for="(reason, index) in whyChooseUsReasons"
          :key="index"
        >
          <div class="border" style="position:relative">
            <v-btn
              rounded
              color="error"
              style="position:absolute; top:0; right:0"
              @click="deleteWhyChooseUsHandler(index)"
            >
              X
            </v-btn>
            <div class="d-flex">
              <v-text-field
                outlined
                label="Headline"
                placeholder="Enter ..."
                counter="500"
                v-model="whyChooseUsReasons[index].headline"
              ></v-text-field>
              <span class="text-red">*</span>
            </div>
            <div class="d-flex">
              <v-textarea
                outlined
                label="Why Choose Us"
                placeholder="Enter ..."
                counter="1000"
                v-model="whyChooseUsReasons[index].message"
              ></v-textarea>
              <span class="text-red">*</span>
            </div>
            <div>
              <v-btn
                color="success"
                @click="whyChooseUsSaveBtnHandler(whyChooseUsReasons[index])"
                >Update</v-btn
              >
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <br>
      </v-row>
      <!-- Service -->
      <v-row>
        <v-col cols="12">
          <div class="text-center section_headline">
            Service ({{ services.length }})
          </div>
        </v-col>
        <v-col cols="6">
          <div class="border" style="position:relative">
            <div class="d-flex">
              <v-textarea
                outlined
                label="Service"
                placeholder="Enter ..."
                counter="1000"
                v-model="serviceFormData.message"
              ></v-textarea>
              <span class="text-red">*</span>
            </div>
            <div>
              <v-btn color="primary" @click="serviceSaveBtnHandler()">
                Save
              </v-btn>
            </div>
          </div>
        </v-col>

        <v-col cols="6" v-for="(reason, index) in services" :key="index">
          <div class="border" style="position:relative">
            <v-btn
              rounded
              color="error"
              style="position:absolute; top:0; right:0"
              @click="deleteServiceHandler(index)"
            >
              X
            </v-btn>

            <div class="d-flex">
              <v-textarea
                outlined
                label="Service"
                placeholder="Enter ..."
                counter="1000"
                v-model="services[index].message"
              ></v-textarea>
              <span class="text-red">*</span>
            </div>
            <div>
              <v-btn
                color="success"
                @click="serviceSaveBtnHandler(services[index])"
                >Update</v-btn
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import * as api from "../config/api.js";

export default {
  beforeMount() {
    this.getAllWhyChooseUsData();
    this.getAllService();
  },
  components: {},
  computed: {},
  data() {
    return {
      loadingState: false,
      /* Why Choose */
      whyChooseUsReasons: [],
      whyChooseUsFormData: {
        headline: "",
        message: "",
      },
      initWhyChooseUsFormData: {
        headline: "",
        message: "",
      },
      /* Servie */
      services: [],
      serviceFormData: {
        headline: "",
        message: "",
      },
      initServiceFormData: {
        headline: "",
        message: "",
      },
    };
  },
  methods: {
    whyChooseUsSaveBtnHandler(data = null) {
      if (data) {
        this.createOrUpdateWhyChooseUsData(data);
      } else {
        this.createOrUpdateWhyChooseUsData(this.whyChooseUsFormData, true);
      }
    },
    createOrUpdateWhyChooseUsData(data, creation = false) {
      if (this.whyChooseUsValidator(data)) {
        this.loadingState = true;
        this.$http
          .post(api.WHY_CHOOSE_US, { ...data })
          .then((res) => {
            alert("Saved");
            if (creation) {
              this.whyChooseUsReasons.push({ ...res.data });
              this.whyChooseUsFormData = { ...this.initWhyChooseUsFormData };
            }
          })
          .catch((error) => {
            alert("Error Occurred");
            console.log(" Error=======>", error.response.data);
            if (error.response.status == 403) {
              alert("validation error");
            }
          })
          .finally(() => {
            this.loadingState = false;
          });
      } else {
        alert("Validation failed");
      }
    },
    whyChooseUsValidator(data) {
      if (data.headline.length && data.message.length) {
        return true;
      }
      return false;
    },
    getAllWhyChooseUsData() {
      this.loadingState = true;
      this.$http
        .get(api.WHY_CHOOSE_US)
        .then((res) => {
          this.whyChooseUsReasons = res.data;
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    deleteWhyChooseUsHandler(index) {
      if (confirm("Do you want to procceed?")) {
        this.loadingState = true;
        this.$http
          .delete(api.WHY_CHOOSE_US + "/" + this.whyChooseUsReasons[index].id)
          .then((res) => {
            alert("Deleted");
            this.whyChooseUsReasons = this.whyChooseUsReasons.filter(
              (e) => e.id != this.whyChooseUsReasons[index].id
            );
          })
          .catch((error) => {
            alert("Error Occurred");
            console.log(" Error=======>", error.response.data);
          })
          .finally(() => {
            this.loadingState = false;
          });
      }
    },
    /* Service */
    serviceSaveBtnHandler(data = null) {
      if (data) {
        this.createOrUpdateServiceData(data);
      } else {
        this.createOrUpdateServiceData(this.serviceFormData, true);
      }
    },
    createOrUpdateServiceData(data, creation = false) {
      if (this.serviceValidator(data)) {
        this.loadingState = true;
        this.$http
          .post(api.SERVICE, { ...data })
          .then((res) => {
            alert("Saved");
            if (creation) {
              this.services.push({ ...res.data });
              this.serviceFormData = { ...this.initServiceFormData };
            }
          })
          .catch((error) => {
            alert("Error Occurred");
            console.log(" Error=======>", error.response.data);
            if (error.response.status == 403) {
              alert("validation error");
            }
          })
          .finally(() => {
            this.loadingState = false;
          });
      } else {
        alert("Validation failed");
      }
    },
    serviceValidator(data) {
      if (data.message.length) {
        return true;
      }
      return false;
    },
    getAllService() {
      this.loadingState = true;
      this.$http
        .get(api.SERVICE)
        .then((res) => {
          this.services = res.data;
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    deleteServiceHandler(index) {
      if (confirm("Do you want to procceed?")) {
        this.loadingState = true;
        this.$http
          .delete(api.SERVICE + "/" + this.services[index].id)
          .then((res) => {
            alert("Deleted");
            this.services = this.services.filter(
              (e) => e.id != this.services[index].id
            );
          })
          .catch((error) => {
            alert("Error Occurred");
            console.log(" Error=======>", error.response.data);
          })
          .finally(() => {
            this.loadingState = false;
          });
      }
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped>
.section_headline {
  background-color: rgb(94, 94, 94);
  color: white;
  font-size: 30px;
  font-weight: 500;
  padding: 4px;
  border-radius: 5px;
}
</style>
