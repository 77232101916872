<template>
  <v-app>
    <loader v-if="loadingState" />
    <div v-else style="height: 100vh">
      <v-row
        v-if="!isAuthenticated"
        class="d-flex justify-content-center align-items-center w-100"
        style="height: 80%"
      >
        <login class="mx-auto" />
      </v-row>
      <v-row class="d-flex" v-else>
        <div class="sidebar">
          <Drawer class="drawer" />
        </div>
        <div class="content pa-3">
          <router-view></router-view>
        </div>
      </v-row>
    </div>
  </v-app>
</template>

<script>
import * as api from "./config/api.js";
import Drawer from "./drawer";
import Login from "./pages/login.vue";
export default {
  name: "App",

  beforeMount() {
    this.getAuthenticationStatus();
  },
  components: {
    Drawer,
    Login,
  },

  data() {
    return {
      loadingState: false,
      isAuthenticated: false,
    };
  },
  methods: {
    getAuthenticationStatus() {
      this.loadingState = true;
      this.$http
        .post(api.AUTH)
        .then((res) => {
          this.isAuthenticated = true;
          console.log(res.data);
        })
        .catch((error) => {
          console.log("login===> ", error.response);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
  },
};
</script>
<style scoped>
@import url("./css/basicStyle.css");
@import url("./css/formStyle.css");
.sidebar {
  width: 20%;
  padding-top: 12px;
}
.drawer {
  top: 0px;
  position: sticky;
  margin-left: 10px;
}
.content {
  width: 80%;
}
</style>
