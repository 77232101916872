<template>
  <div>
    <loader v-if="loadingState" />
    <v-card
      v-else
      style="width: 100%; max-width: 500px; margin: auto; padding: 15px"
    >
      <v-row class="">
        <v-col cols="12">
          <v-text-field
            outlined
            label="Username"
            prepend-inner-icon="fa-envelope"
            v-model="formData.username"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            outlined
            label="Password"
            prepend-inner-icon="fa-lock"
            v-model="formData.password"
            type="password"
          ></v-text-field>
        </v-col>
        <!-- Buttons -->
        <v-col cols="12" class="text-center">
          <v-btn @click="loginButtonHandler()" color="primary">Log In</v-btn>
          <v-btn medium color="error">Cancel</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template> 


<script>
import * as api from "../config/api.js";
export default {
  beforeMount() {
    // this.checkIfUserIsLoggedIn();
  },
  components: {},
  computed: {},
  data() {
    return {
      loadingState: false,
      formData: {
        username: "tokyomotors",
        password: "123456",
      },
    };
  },
  methods: {
    gotorouterview(link) {},
    loginButtonHandler() {
      this.loadingState = true;
      this.$http
        .post(api.LOGIN, {
          username: this.formData.username,
          password: this.formData.password,
        })
        .then((res) => {
          if (res.data.access_token) {
            let token = JSON.stringify(res.data.access_token);
            localStorage.setItem("token", token);
            window.location.href = "/admin";
          }
        })
        .catch((error) => {
          alert("Login Failed");
          console.log("error===>", error);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    // checkIfUserIsLoggedIn() {
    //   if (localStorage.getItem("token")) {
    //     localStorage.setItem("token", "");
    //     window.location.reload();
    //   }
    // },
  },
  mounted() {},
};
</script>


<style >
@media (min-width: 600px) {
  .responsive_1 {
    width: 400px;
  }
}
</style>