<template>
  <div>
    <loader v-if="loadingState" />
    <div class="pa-4" v-else>
      <v-row class="">
        <v-col
          class="mx-auto form_container"
          cols="12"
          xl="6"
          lg="6"
          md="9"
          sm="12"
        >
          <errors :errors="axiosError" />
          <br />
          <v-form>
            <!-- Images -->
            <div class="d-flex">
              <v-file-input
                show-size
                prepend-icon=""
                outlined
                label="File input"
                @change="fileChangeHandler"
                accept="image/*"
                append-icon="fa-file-upload"
                multiple
                v-model="storage"
              ></v-file-input>
              <span class="text-red">*</span>
            </div>
            <div>
              <v-row>
                <v-col
                  cols="4"
                  v-for="(slider, index) in formData.sliders"
                  :key="index"
                >
                  <div>
                    <v-img :src="slider.picPath" contain width="100%"></v-img>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="text-center">
              <v-btn color="primary" @click="saveBtnHandler">Save</v-btn>
              <v-btn color="error" @click="reset">Cancel</v-btn>
            </div>
          </v-form>
        </v-col>
      </v-row>
      <v-row class="text-center mt-12">
        <data-table
          class="w-100"
          :tableData="dataArray"
          :tableHeaders="headerArray"
          :enableEdit="false"
          @deleteHandler="deleteData"
        />
      </v-row>
    </div>
  </div>
</template>

<script>
import { fileToBase64Converter } from "../lib/helperFunctions.js";
import * as api from "../config/api.js";
export default {
  beforeMount() {
    this.getAllData();
  },
  components: {},
  computed: {},
  data() {
    return {
      loadingState: false,
      axiosError: [],
      formData: {
        sliders: [],
      },
      initFormData: {
        sliders: [],
      },
      storage: [],
      dataArray: [],
      headerArray: [
        { text: "Id", value: "id" },
        { text: "Image", value: "picPath" },
        { text: "Creation Date", value: "created_at" },
        { text: "", value: "actions" },
      ],
    };
  },
  methods: {
    async fileChangeHandler() {
      for (let i = 0; i < this.storage.length; i++) {
        let convertedFile = await fileToBase64Converter(this.storage[i]);
        if (convertedFile) {
          this.formData.sliders.push({ picPath: convertedFile });
        }
      }
    },
    getAllData() {
      this.loadingState = true;
      this.$http
        .get(api.SLIDER)
        .then((res) => {
          this.dataArray = res.data;
          console.log(this.dataArray)
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    saveBtnHandler() {
      this.loadingState = true;
      this.$http
        .post(api.SLIDER, { ...this.formData })
        .then((res) => {
          alert("Saved");
          console.log("Res==>", res.data);
          this.dataArray = {...res.data};
          this.reset();
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            this.axiosError = error.response.data;
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    deleteData(data) {
      if (confirm("Do you want to delete this?")) {
        this.loadingState = true;
        this.$http
          .delete(api.SLIDER + "/" + data.id)
          .then((res) => {
            alert("Deleted");
            this.dataArray = this.dataArray.filter((e) => e.id != data.id);
            this.reset();
          })
          .catch((error) => {
            alert("Error Occurred");
            console.log(" Error=======>", error.response.data);
            if (error.response.status == 403) {
              this.axiosError = error.response.data;
            }
          })
          .finally(() => {
            this.loadingState = false;
          });
      }
    },
    reset() {
      this.formData = { ...this.initFormData };
      this.storage = [];
    },
   
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped></style>
