<template>
  <v-card :height="getHeight">
    <v-navigation-drawer :right="false" width="100%" permanent>
      <v-list dense nav v-for="(item, index) in items" :key="index">
        <v-list-item v-if="!item.subList" :to="item.link" link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group :prepend-icon="item.icon" v-else>
          <template v-slot:activator>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </template>

          <v-list-item
            v-for="subItem in item.subList"
            :key="subItem.title"
            :to="subItem.link"
          >
            <v-list-item-icon class="pl-4">
              <v-icon>{{ subItem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ subItem.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>

      <template v-slot:append>
        <div>
          <v-btn @click="logout" color="error" block>Logout</v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import * as slug from "./config/slug.js";
export default {
  computed: {
    getHeight() {
      let height = screen.height;

      return height - 150 + "px";
    },
  },
  data() {
    return {
      items: [
        /* Home */
        {
          title: "Home",
          icon: "fa fa-home",
          link: "",
          subList: [
            {
              title: "Why Choose Us",
              icon: "fa fa-home",
              link: slug.HOME,
            },
            {
              title: "Slider",
              icon: "fa fa-home",
              link: slug.SLIDER,
            },
          ],
        },
        /* About */
        {
          title: "About",
          icon: "fa fa-home",
          link: "",
          subList: [
            {
              title: "About Us",
              icon: "fa fa-home",
              link: slug.ABOUT_US,
            },
            {
              title: "Our Services",
              icon: "fa fa-home",
              link: slug.OUR_SERVICES,
            },
          ],
        },
        {
          title: "Brand",
          icon: "fa fa-home",
          link: slug.BRAND,
          // subList: [],
        },
        {
          title: "Cars",
          icon: "fa-car",
          link: slug.CAR,
        },
        {
          title: "Contact",
          icon: "fa fa-home",
          link: "",
          subList: [
            {
              title: "Contact Persons",
              icon: "fa fa-home",
              link: slug.CONTACT_PERSON,
            },
            {
              title: "Contact Information",
              icon: "fa fa-home",
              link: slug.CONTACT_INFORMATION,
            },
            {
              title: "Contact Messages",
              icon: "fa fa-home",
              link: slug.CONTACT_MESSAGE,
            },
          ],
        },
      ],
      right: null,
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      window.location.reload();
    },
  },
};
</script>

<style scoped></style>
