// const HOST = "http://localhost:8000";
const HOST = "https://backend.tokyomotors.com.bd";
const PREFIX = "api/admin";
const BASE_URL = `${HOST}/${PREFIX}`;

export const LOGIN = `${HOST}/api/auth/login`;
export const AUTH = `${HOST}/api/auth/me`;

export const BRAND = `${BASE_URL}/brand`;

export const CAR = `${BASE_URL}/car`;
export const DELETE_CAR_IMAGE = `${BASE_URL}/car/image`; /* /:carImageId  will be added  */

export const CONTACT_PERSON = `${BASE_URL}/contact/person`;
export const CONTACT_MESSAGE = `${BASE_URL}/contact/message`;
export const CONTACT_PAGE_DATA = `${BASE_URL}/contact/page/data`;

export const WHY_CHOOSE_US = `${BASE_URL}/home/whychooseus`;
export const SERVICE = `${BASE_URL}/home/service`;
export const SLIDER = `${BASE_URL}/home/slider`;
export const ABOUT_US = `${BASE_URL}/about/us`;
export const OUR_SERVICES = `${BASE_URL}/about/services`;
// export const SLIDER = `${BASE_URL}/home/slider`
