<template>
  <div>
    <loader v-if="loadingState" />
    <div class="pa-4" v-else>
      <v-row class="">
        <v-col
          class="mx-auto form_container"
          cols="12"
          xl="6"
          lg="6"
          md="9"
          sm="12"
        >
          <errors :errors="axiosError" />
          <br />
          <v-form>
            <!-- Name -->
            <div class="d-flex">
              <v-text-field
                label="Name"
                placeholder="insert contact's name"
                outlined
                full-width
                v-model="formData.name"
              ></v-text-field>
              <span class="text-red">*</span>
            </div>
            <!-- Designation -->
            <div class="d-flex">
              <v-text-field
                label="Designation"
                placeholder="insert contact's designation"
                outlined
                full-width
                v-model="formData.designation"
              ></v-text-field>
              <span class="text-red">*</span>
            </div>
            <!-- Phone -->
            <div class="d-flex">
              <v-text-field
                label="Phone"
                placeholder="insert contact's phone"
                outlined
                full-width
                v-model="formData.phone"
              ></v-text-field>
              <span class="text-red">*</span>
            </div>
            <!-- Serial -->
            <div class="d-flex">
              <v-text-field
                label="Serial"
                placeholder="insert contact's serial"
                outlined
                full-width
                v-model="formData.serial"
                type="number"
              ></v-text-field>
              <span class="text-red">*</span>
            </div>

            <div class="text-center">
              <v-btn color="primary" @click="saveBtnHandler">{{editMode? 'Update' : 'Save'}}</v-btn>
              <v-btn color="error" @click="reset">Cancel</v-btn>
            </div>
          </v-form>
        </v-col>
      </v-row>
      <v-row class="text-center mt-12">
        <data-table
          class="w-100"
          :tableData="dataArray"
          :tableHeaders="headerArray"
          @editHandler="editData"
          @deleteHandler="deleteData"
        />
      </v-row>
    </div>
  </div>
</template>

<script>
import * as api from "../config/api.js";

export default {
  beforeMount() {
    this.getAllData();
  },
  components: {},
  computed: {},
  data() {
    return {
      loadingState: false,
      dataArray: [],
      headerArray: [
        { text: "Id", value: "id" },
        { text: "Name", value: "name" },
        { text: "Designation", value: "designation" },
        { text: "Phone", value: "phone" },
        { text: "Serial", value: "serial" },
        { text: "Date", value: "created_at" },
        { text: "", value: "actions" },
      ],
      formData: {
        name: "",
        designation: "",
        phone: "",
        serial: "",
      },
      initFormData: {
        name: "",
        designation: "",
        phone: "",
        serial: "",
      },
      axiosError: [],
      editMode: false,
    };
  },
  methods: {
    saveBtnHandler() {
      this.createData();
    },
    getAllData() {
      this.loadingState = true;
      this.$http
        .get(api.CONTACT_PERSON)
        .then((res) => {
          this.dataArray = res.data;
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    createData() {
      this.loadingState = true;
      this.$http
        .post(api.CONTACT_PERSON, { ...this.formData })
        .then((res) => {
          alert("Saved");
          console.log("Res==>", res.data);
          if (this.editMode) {
            this.dataArray = this.dataArray.map((e) => {
              if (e.id == res.data.id) {
                e = { ...res.data };
              }
              return e;
            });
          } else {
            this.dataArray.push(res.data);
          }
          this.reset();
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            this.axiosError = error.response.data;
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    editData(e) {
      this.formData = { ...e };
      window.scrollTo(0, 0);
      this.editMode = true;
    },
    deleteData(data) {
      
      if (confirm("Do you want to delete this?")) {
        this.loadingState = true;
        this.$http
          .delete(api.CONTACT_PERSON + "/" + data.id)
          .then((res) => {
            alert("Deleted");
            this.dataArray = this.dataArray.filter(
              (e) => e.id != data.id
            );
            this.reset();
          })
          .catch((error) => {
            alert("Error Occurred");
            console.log(" Error=======>", error.response.data);
            if (error.response.status == 403) {
              this.axiosError = error.response.data;
            }
          })
          .finally(() => {
            this.loadingState = false;
          });
      }
    },
    reset() {
      this.formData = { ...this.initFormData };
      this.loadingState = false;
      this.editMode = false;
      this.axiosError = []
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped></style>
