import Vue from "vue";
import VueRouter from "vue-router";
import * as slug from "./slug.js";

import Home from "../pages/home.vue";
import Slider from "../pages/slider.vue";
import Brand from "../pages/brand.vue";
import Car from "../pages/car.vue";
import ContactPerson from "../pages/contactPerson.vue";
import ContactMessage from "../pages/contactMessage.vue";
import ContactInformation from "../pages/contactInformation.vue";
import AboutUs from "../pages/aboutUs.vue";
import OurServices from "../pages/ourServices.vue";
import Test from "../pages/test.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",

  routes: [
    {
      path: slug.HOME,
      component: Home,
    },
    {
      path: slug.ABOUT_US,
      component: AboutUs,
    },
    {
      path: slug.OUR_SERVICES,
      component: OurServices,
    },
    {
      path: slug.SLIDER,
      component: Slider,
    },
    {
      path: slug.BRAND,
      component: Brand,
    },
    {
      path: slug.CAR,
      component: Car,
    },
    {
      path: slug.CONTACT_PERSON,
      component: ContactPerson,
    },
    {
      path: slug.CONTACT_MESSAGE,
      component: ContactMessage,
    },
    {
      path: slug.CONTACT_INFORMATION,
      component: ContactInformation,
    },

    // Test
    {
      path: slug.TEST,
      component: Test,
    },
  ],
});

export default router;
