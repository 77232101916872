<template>
  <div>
    <loader v-if="loadingState" />
    <div class="pa-4" v-else>
      <v-row class="">
        <v-col
          class="mx-auto form_container"
          cols="12"
          xl="8"
          lg="8"
          md="8"
          sm="12"
        >
          <v-form>
            <v-row v-if="axiosError.length">
              <errors :errors="axiosError" />
            </v-row>
            <v-row>
              <!-- Brand -->
              <v-col cols="12" class="d-flex">
                <v-autocomplete
                  v-model="formData.brandId"
                  :items="brands"
                  item-text="name"
                  item-value="id"
                  outlined
                  label="Brand"
                  dense
                  hide-details
                ></v-autocomplete>
                <span class="text-red">*</span>
              </v-col>
              <!-- Banner Image -->
              <v-col cols="12" class="d-flex">
                <div class="w-100">
                  <v-file-input
                    prepend-icon=""
                    outlined
                    label="Banner Image"
                    @change="fileChangeHandler"
                    accept="image/*"
                    append-icon="fa-file-upload"
                    v-if="!formData.bannerImage"
                    dense
                    hide-details
                  ></v-file-input>
                  <div style="position: relative" v-else>
                    <v-img :src="formData.bannerImage"></v-img>
                    <v-btn
                      rounded
                      color="error"
                      style="position: absolute; top: 0; right: 0"
                      @click="removeBannerImage"
                    >
                      X
                    </v-btn>
                  </div>
                </div>
                <span class="text-red">*</span>
              </v-col>
              <!-- Images -->
              <v-col cols="12">
                <div class="d-flex">
                  <v-file-input
                    prepend-icon=""
                    outlined
                    label="Car Images"
                    @change="imagesHandler"
                    accept="image/*"
                    append-icon="fa-file-upload"
                    multiple
                    v-model="storage"
                    dense
                  ></v-file-input>
                  <span class="text-red">*</span>
                </div>
                <!-- showcase -->
                <div>
                  <v-row>
                    <v-col
                      cols="4"
                      v-for="(image, index) in formData.images"
                      :key="index"
                    >
                      <div style="position: relative">
                        <v-img
                          :src="image.picPath"
                          contain
                          width="100%"
                        ></v-img>
                        <v-btn
                          rounded
                          color="error"
                          style="position: absolute; top: 0; right: 0"
                          @click="deleteCarImage(index)"
                        >
                          X
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>

              <!-- Name -->
              <v-col cols="12" class="d-flex">
                <v-text-field
                  outlined
                  label="Name"
                  placeholder="insert car name"
                  counter="500"
                  v-model="formData.name"
                  dense
                  hide-details
                ></v-text-field>
                <span class="text-red">*</span>
              </v-col>
              <!-- Body -->
              <v-col cols="12" class="d-flex">
                <v-text-field
                  label="Body"
                  placeholder="insert body"
                  outlined
                  v-model="formData.body"
                  dense
                  hide-details
                ></v-text-field>
                <span class="text-red">*</span>
              </v-col>
              <!-- Drive -->
              <v-col cols="12" class="d-flex">
                <v-text-field
                  label="Drive"
                  placeholder="insert drive"
                  outlined
                  v-model="formData.drive"
                  dense
                  hide-details
                ></v-text-field>
                <span class="text-red">*</span>
              </v-col>
              <!-- Model -->
              <v-col cols="12" class="d-flex">
                <v-text-field
                  label="Model"
                  placeholder="insert model"
                  outlined
                  v-model="formData.model"
                  dense
                  hide-details
                ></v-text-field>
                <span class="text-red">*</span>
              </v-col>

              <!-- Mileage -->
              <v-col cols="12" class="d-flex">
                <v-text-field
                  label="Mileage"
                  placeholder="insert mileage"
                  outlined
                  v-model="formData.mileage"
                  dense
                  hide-details
                ></v-text-field>
                <span class="text-red">*</span>
              </v-col>
              <!-- Fuel Type -->
              <v-col cols="12" class="d-flex">
                <v-text-field
                  label="Fuel Type"
                  placeholder="insert fuel type"
                  outlined
                  v-model="formData.fuelType"
                  dense
                  hide-details
                ></v-text-field>
                <span class="text-red">*</span>
              </v-col>
              <!-- Engine -->
              <v-col cols="12" class="d-flex">
                <v-text-field
                  label="Engine"
                  placeholder="insert engine"
                  outlined
                  v-model="formData.engine"
                  dense
                  hide-details
                ></v-text-field>
                <span class="text-red">*</span>
              </v-col>
              <!-- Chassis Number -->
              <v-col cols="12" class="d-flex">
                <v-text-field
                  label="Chassis Number"
                  placeholder="insert chassis number"
                  outlined
                  v-model="formData.chassisNumber"
                  dense
                  hide-details
                ></v-text-field>
                <span class="text-red">*</span>
              </v-col>
              <!-- Package -->
              <v-col cols="12" class="d-flex">
                <v-text-field
                  label="Package"
                  placeholder="insert package"
                  outlined
                  v-model="formData.package"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <!-- Auction Grade -->
              <v-col cols="12" class="d-flex">
                <v-text-field
                  label="Auction Grade"
                  placeholder="insert auction grade"
                  outlined
                  v-model="formData.auctionGrade"
                  dense
                  hide-details
                ></v-text-field>
                <span class="text-red">*</span>
              </v-col>

              <!-- Transmission -->
              <v-col cols="12" class="d-flex">
                <v-text-field
                  label="Transmission"
                  placeholder="insert car transmission"
                  outlined
                  v-model="formData.transmission"
                  dense
                  hide-details
                ></v-text-field>
                <span class="text-red">*</span>
              </v-col>

              <!-- Exterior Color -->
              <v-col cols="12" class="d-flex">
                <v-text-field
                  label="Exterior Color"
                  placeholder="insert car exterior color"
                  outlined
                  v-model="formData.exteriorColor"
                  dense
                  hide-details
                ></v-text-field>
                <span class="text-red">*</span>
              </v-col>

              <!-- Interior Color -->
              <v-col cols="12" class="d-flex">
                <v-text-field
                  label="Interior Color"
                  placeholder="insert car interior color"
                  outlined
                  v-model="formData.interiorColor"
                  dense
                  hide-details
                ></v-text-field>
                <span class="text-red">*</span>
              </v-col>
              <!-- Description -->
              <v-col cols="12" class="d-flex">
                <tip-tap
                  label="Description"
                  placeholder="insert car description"
                  outlined
                  v-model="formData.description"
                ></tip-tap>
                <span class="text-red">*</span>
              </v-col>

              <!-- Active Status -->
              <v-col cols="12" class="d-flex">
                <v-radio-group
                  v-model="formData.activeStatus"
                  label="Active Status"
                  row
                  class="border w-100"
                >
                  <v-radio label="Not Sold" :value="true"></v-radio>
                  <v-radio label="Sold" :value="false"></v-radio>
                </v-radio-group>
                <span class="text-red">*</span>
              </v-col>

              <v-col cols="12" class="text-center">
                <v-btn color="primary" @click="saveBtnHandler">Save</v-btn>
                <v-btn color="error" @click="reset">Cancel</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <v-row class="text-center mt-12">
        <data-table
          class="w-100"
          :tableData="dataArray"
          :tableHeaders="headerArray"
          @editHandler="editData"
          @deleteHandler="deleteData"
        />
      </v-row>
    </div>
  </div>
</template>

<script>
import { fileToBase64Converter } from "../lib/helperFunctions.js";

import * as api from "../config/api.js";
import TipTap from "../components/tiptap/tiptap.vue";

export default {
  beforeMount() {
    this.getAllBrand();
    this.getAllData();
  },
  components: {
    TipTap,
  },
  computed: {},
  data() {
    return {
      loadingState: false,
      editMode: true,
      brands: [],
      dataArray: [],
      headerArray: [
        { text: "Id", value: "id" },
        { text: "Name", value: "name" },
        { text: "Body", value: "body" },
        { text: "Drive", value: "drive" },
        { text: "Model", value: "model" },
        { text: "Images", value: "images" },
        { text: "Fuel Type", value: "fuelType" },
        { text: "Mileage", value: "mileage" },
        { text: "Engine", value: "engine" },
        { text: "Chassis", value: "chassisNumber" },
        { text: "Package", value: "package" },
        { text: "Auction", value: "auctionGrade" },
        { text: "Transmission", value: "transmission" },
        { text: "Exterior Color", value: "exteriorColor" },
        { text: "Interior Color", value: "interiorColor" },
        { text: "Description", value: "description" },
        { text: "Active Status", value: "activeStatus" },
        { text: "Date", value: "created_at" },
        { text: "", value: "actions" },
      ],
      storage: [],
      formData: {
        brandId: null,
        bannerImage: null,
        name: null,
        body: null,
        mileage: null,
        fuelType: null,
        engine: null,
        chassisNumber: null,
        package: null,
        auctionGrade: null,
        transmission: null,
        drive: null,
        model: null,
        exteriorColor: null,
        interiorColor: null,
        description: null,
        activeStatus: null,
        images: [],
      },
      initFormData: {
        brandId: null,
        bannerImage: null,
        name: null,
        body: null,
        mileage: null,
        fuelType: null,
        engine: null,
        chassisNumber: null,
        package: null,
        auctionGrade: null,
        transmission: null,
        drive: null,
        model: null,
        exteriorColor: null,
        interiorColor: null,
        description: null,
        activeStatus: null,
        images: [],
      },
      formRules: {},
      axiosError: [],
    };
  },
  methods: {
    getAllBrand() {
      this.$http
        .get(api.BRAND)
        .then((res) => {
          this.brands = res.data;
          // console.log("brands===>", res.data);
        })
        .catch((error) => {
          console.log("error===>", error.response.data);
        })
        .finally(() => {});
    },
    fileChangeHandler(event) {
      let reader = new FileReader();
      reader.readAsDataURL(event);
      reader.addEventListener("load", () => {
        this.formData.bannerImage = reader.result;
      });
    },
    async imagesHandler() {
      for (let i = 0; i < this.storage.length; i++) {
        let convertedFile = await fileToBase64Converter(this.storage[i]);
        if (convertedFile) {
          this.formData.images.push({ picPath: convertedFile });
        }
      }
    },
    getAllData() {
      this.loadingState = true;
      this.$http
        .get(api.CAR)
        .then((res) => {
          this.dataArray = res.data;
          console.log("data==>", this.dataArray);
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    saveBtnHandler() {
      console.log(this.formData);
      this.loadingState = true;
      this.$http
        .post(api.CAR, { ...this.formData })
        .then((res) => {
          alert("saved");
          console.log("Res==>", res.data);
          this.reset();
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            this.axiosError = error.response.data;
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    editData(e) {
      this.formData = { ...e };
      window.scrollTo(0, 0);
      this.editMode = true;
    },
    deleteData(data) {
      if (confirm("Do you want to delete this?")) {
        this.loadingState = true;
        this.$http
          .delete(api.CAR + "/" + data.id)
          .then((res) => {
            alert("Deleted");
            this.dataArray = this.dataArray.filter((e) => e.id != data.id);
            this.reset();
          })
          .catch((error) => {
            alert("Error Occurred");
            console.log(" Error=======>", error.response.data);
            if (error.response.status == 403) {
              this.axiosError = error.response.data;
            }
          })
          .finally(() => {
            this.loadingState = false;
          });
      }
    },
    deleteCarImage(imageIndex) {
      if (confirm("Do you want to delete this?")) {
        if (
          this.formData.images[imageIndex] &&
          this.formData.images[imageIndex].id
        ) {
          this.$http
            .delete(
              api.DELETE_CAR_IMAGE + "/" + this.formData.images[imageIndex].id
            )
            .then((res) => {
              this.formData.images = this.formData.images.filter(
                (e, index) => index != imageIndex
              );
              console.log(res.data);
            })
            .catch((error) => {
              alert("Error Occurred");
              console.log(" Error=======>", error.response.data);
            })
            .finally(() => {});
        } else {
          this.formData.images = this.formData.images.filter(
            (e, index) => index != imageIndex
          );
        }
      }
    },
    removeBannerImage() {
      this.formData.bannerImage = "";
    },
    reset() {
      this.formData = { ...this.initFormData };
      this.axiosError = [];
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped></style>
