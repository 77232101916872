export const HOME = "/";
export const SLIDER = "/home/slider";
export const BRAND = "/brand";
export const CAR = "/car";
export const CONTACT_PERSON = "/contact/person";
export const CONTACT_MESSAGE = "/contact/message";
export const CONTACT_INFORMATION = "/contact/information";
export const ABOUT_US = "/about/us";
export const OUR_SERVICES = "/about/services";

//Test(This page is only for testing component/s)
export const TEST = "/test";
